import axios from "axios";

class expensesApi {
  async get({ start_date, end_date, page, limit, search }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post(`/expenses/get`, {
        start_date,
        end_date,
        page,
        limit,
        search,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async add({ expenses_name, money, date, note }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post(`/expenses/add`, {
        expenses_name,
        money,
        date,
        note,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async edit({ expenses_name, money, date, note, id }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .put(`/expenses/edit`, {
        expenses_name,
        money,
        date,
        note,
        id,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .delete(`/expenses/delete/${id}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }
}

export default new expensesApi();
